#logo {
  top: var(--logo-top);
  padding: var(--logo-padding) 0 var(--logo-padding) 0;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
  align-self: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
#logo:before {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 900px;
}
#logo #logo-svg {
  width: var(--logo-width);
  position: relative;
  pointer-events: none;
  overflow: hidden;
}
#logo #logo-svg:before, #logo #logo-svg:after {
  content: "";
  position: absolute;
  width: 100%;
  background: url("harlekid.311eaf86.svg") no-repeat;
  background-size: cover;
}
#logo #logo-svg:before {
  height: 40%;
}
#logo #logo-svg:after {
  height: 60%;
  background-position: 0 100%;
  top: 40%;
}
#logo #logo-svg img {
  display: block;
  visibility: hidden;
}
#logo #logo-svg.glitch-top:before {
  animation: glitch-top 50ms infinite step-start;
}
#logo #logo-svg.glitch-bottom:after {
  animation: glitch-bottom 50ms infinite step-start;
}
#logo.header {
  top: 0;
  padding: 5px 0 5px 0;
}
#logo.header #logo-svg {
  width: 180px;
}
#logo.transition {
  transition: top 250ms ease-in, padding 250ms ease-in;
}
#logo.transition #logo-svg {
  transition: width 500ms ease-out 250ms;
}
@media only screen and (max-height: 450px) {
  #logo::before {
    width: 240px;
  }
}
@keyframes glitch-top {
  0%, 100% {
    left: 0;
  }
  50% {
    left: -1%;
  }
}
@keyframes glitch-bottom {
  0%, 100% {
    left: 0;
  }
  50% {
    left: 1%;
  }
}
#player {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1;
  overflow: hidden;
  display: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.3);
}
#player #cover-wrapper {
  position: relative;
  height: 70px;
  width: 70px;
  background-color: rgba(0, 0, 0, 0.3);
}
#player #cover-wrapper #cover-button, #player #cover-wrapper #cover {
  position: absolute;
  height: 100%;
  width: 70px;
}
#player #cover-wrapper #play-button {
  position: absolute;
  padding: 25px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
}
#player #cover-wrapper #play-button #play-icon {
  pointer-events: none;
}
#player #progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
}
#player .player-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 24px;
  padding-left: 8px;
}
#player .player-button#spotify-button {
  padding-right: 8px;
}
#player .player-button#spotify-button.hidden {
  visibility: hidden;
}
#player .player-button#spotify-button #spotify-icon {
  width: 22px;
  height: 100%;
  margin-bottom: 1px;
  -webkit-mask: url("spotify.af56eb03.svg") no-repeat center/contain;
}
#player .player-button#close-button {
  position: relative;
  padding-right: 16px;
}
#player .player-button#close-button #cancel-icon {
  height: 18px;
  width: 18px;
  opacity: 0.7;
}
#player .player-button#close-button:active #cancel-icon {
  opacity: 1;
}
#player #player-text {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  font-family: lemonMilk, serif;
  font-size: 15px;
  color: #fff;
  overflow: hidden;
}
#player #player-text #title {
  width: 100%;
  margin-left: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
}
#player.hidden-controls #cover-button {
  display: none;
}
@media only screen and (min-width: 1000px) {
  #player #player-text #title {
    text-align: center;
  }
}
#play-icon {
  width: 20px;
  height: 20px;
  position: relative;
}
#play-icon:before, #play-icon:after {
  content: "";
  position: absolute;
  width: 0;
  border-style: solid;
  border-color: transparent;
  border-left-color: rgba(255, 255, 255, 0.9);
  transition: 100ms;
  border-top-width: calc(20px / 4);
  border-bottom-width: calc(20px / 4);
  border-left-width: calc(20px / 2);
}
#play-icon:before {
  left: 0;
  top: 0;
  height: calc(20px / 2);
  border-right-width: calc(20px / 2);
}
#play-icon:after {
  left: calc(20px / 2);
  top: calc(20px / 4);
  height: 0;
  border-right-width: 0;
}
#play-icon.playing:before, #play-icon.playing:after {
  height: 20px;
  border-width: 0 0 0 calc(20px / 3);
}
#play-icon.playing:after {
  left: calc(20px / 1.5);
  top: 0;
}
#footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  height: var(--footer-height);
  width: 100%;
  z-index: 1;
  overflow: hidden;
}
#footer .footer-button-group {
  display: flex;
  flex-direction: column;
  margin: 0 5px 0 5px;
}
#footer .footer-button-group .footer-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: var(--item-height);
  width: 45px;
}
#footer .footer-button-group .footer-item#music-button {
  font-family: lemonMilk, serif;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.9);
  padding: 0 15px 0 15px;
  background-color: rgba(0, 0, 0, 0.6);
  animation: pulsate 2s infinite;
  overflow: hidden;
}
#footer .footer-button-group .footer-item .footer-item-content {
  transform: skew(23deg);
  pointer-events: none;
}
#footer .footer-button-group .footer-item .arrow {
  width: 30px;
  margin-top: 15px;
  transition: transform 150ms ease-out;
  animation: glow 2s infinite;
}
#footer .footer-button-group.extended .arrow {
  transform: rotate(180deg);
}
#footer.hidden .footer-button-group {
  transform: skew(-23deg) translateY(var(--footer-height)) !important;
}
#footer.hide-transition .footer-button-group {
  transition: transform 500ms ease-out 250ms;
}
#footer.extend-transition .footer-button-group {
  transition: transform 150ms ease-out;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    margin-top: 2px;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  70% {
    margin-top: 0;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    margin-top: 2px;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes glow {
  0%, 100% {
    opacity: 0.5;
    margin-top: 12px;
  }
  70% {
    opacity: 1;
    margin-top: 10px;
  }
}
body {
  position: absolute;
  display: flex;
  justify-content: center;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: #000;
}

.button {
  cursor: pointer;
  user-select: none;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
}

#smoke-canvas {
  background-image: linear-gradient(#11111f, #2f223f, #11111f);
  overflow: hidden;
  width: 100%;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  background-color: rgba(255, 255, 255, 0.5);
  animation: ripple 500ms linear;
}

@font-face {
  font-family: lemonMilk;
  src: url(LEMONMILK-Bold.76596d34.otf);
}
#stats-container {
  display: flex;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 80px;
  pointer-events: none;
  z-index: 10000;
  transition: transform 100ms ease-out;
}
#stats-container.hidden {
  transform: translateY(-48px);
}
#stats-container.hidden .arrow {
  transform: rotate(180deg);
}
#stats-container div {
  pointer-events: auto;
}
#stats-container .button {
  display: flex;
}
#stats-container .arrow {
  height: 22px;
  margin: 0 5px 0 5px;
  opacity: 0.1;
  transition: transform 100ms ease-out;
}
/*# sourceMappingURL=index.1621dc66.css.map */
