#stats-container {
  display: flex;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 80px;
  pointer-events: none;
  z-index: 10000;
  transition: transform 100ms ease-out;

  &.hidden {
    transform: translateY(-48px);

    .arrow {
      transform: rotate(180deg);
    }
  }

  div {
    pointer-events: auto;
  }

  .button {
    display: flex;
  }

  .arrow {
    height: 22px;
    margin: 0 5px 0 5px;
    opacity: 0.1;
    transition: transform 100ms ease-out;
  }
}
