#footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  height: var(--footer-height);
  width: 100%;
  z-index: 1;
  overflow: hidden;

  .footer-button-group {
    display: flex;
    flex-direction: column;
    margin: 0 5px 0 5px;

    .footer-item {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: var(--item-height);
      width: 45px;

      &#music-button {
        font-family: lemonMilk, serif;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.9);
        padding: 0 15px 0 15px;
        background-color: rgba(0, 0, 0, 0.6);
        animation: pulsate 2s infinite;
        overflow: hidden;
      }

      .footer-item-content {
        transform: skew(23deg);
        pointer-events: none;
      }

      .arrow {
        width: 30px;
        margin-top: 15px;
        transition: transform 150ms ease-out;
        animation: glow 2s infinite;
      }
    }

    &.extended .arrow {
      transform: rotate(180deg);
    }
  }

  &.hidden .footer-button-group {
    transform: skew(-23deg) translateY(var(--footer-height)) !important;
  }

  &.hide-transition .footer-button-group {
    transition: transform 500ms ease-out 250ms;
  }

  &.extend-transition .footer-button-group {
    transition: transform 150ms ease-out;
  }
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    margin-top: 2px;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  70% {
    margin-top: 0;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    margin-top: 2px;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes glow {
  0%, 100% {
    opacity: 0.5;
    margin-top: 12px;
  }
  70% {
    opacity: 1;
    margin-top: 10px;
  }
}
