#player {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1;
  overflow: hidden;
  display: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.3);

  #cover-wrapper {
    position: relative;
    height: 70px;
    width: 70px;
    background-color: rgba(0, 0, 0, 0.3);

    #cover-button, #cover {
      position: absolute;
      height: 100%;
      width: 70px;
    }

    #play-button {
      position: absolute;
      padding: 25px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.1);

      #play-icon {
        pointer-events: none;
      }
    }
  }

  #progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
  }

  .player-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 24px;
    padding-left: 8px;

    &#spotify-button {
      padding-right: 8px;

      &.hidden {
        visibility: hidden;
      }

      #spotify-icon {
        width: 22px;
        height: 100%;
        margin-bottom: 1px;
        -webkit-mask: url('/img/logos/spotify.svg') no-repeat center / contain;
      }
    }

    &#close-button {
      position: relative;
      padding-right: 16px;

      #cancel-icon {
        height: 18px;
        width: 18px;
        opacity: 0.7;
      }

      &:active #cancel-icon {
        opacity: 1;
      }
    }
  }

  #player-text {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    font-family: lemonMilk, serif;
    font-size: 15px;
    color: #fff;
    overflow: hidden;

    #title {
      width: 100%;
      margin-left: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.hidden-controls {
    #cover-button {
      display: none;
    }
  }
}

@media only screen and (min-width: 1000px) {
  #player #player-text #title {
    text-align: center;
  }
}
