body {
  position: absolute;
  display: flex;
  justify-content: center;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: #000;
}

.button {
  cursor: pointer;
  user-select: none;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
}

#smoke-canvas {
  background-image: linear-gradient(#11111f, #2f223f, #11111f);
  overflow: hidden;
  width: 100%;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  background-color: rgba(255, 255, 255, 0.5);
  animation: ripple 500ms linear;
}

@font-face {
  font-family: lemonMilk;
  src: url(../font/LEMONMILK-Bold.otf);
}
